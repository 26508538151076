import React, { useState }  from "react"
import axios from "axios";
import Layout from "../components/layout"
import Seo from "../components/seo"

const description = "Dijital ürünlerle alakalı tüm sorularınız için tio'yla iletişim kurabilirsiniz."

const ContactPage = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    text: "Aklınıza takınlanları sorabilirsiniz. "
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
      text: "Teşekkür ederiz. En yakın zamanda size dönüş yapacağız."
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true, text:"Yükleniyor..." });
    axios({
      method: "post",
      url: "https://getform.io/f/d9a723b6-b4b7-4380-9cef-957ece024353",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };



  return (
  <Layout navbarTheme="dark" className="bg-cream">
    <Seo title="İletişim" description={description} canonical="https://tio.ist/tr/contact/" />
 
    <div className=" relative py-20">

      <div className="container text-center mx-auto  py-10 lg:px-10 max-w-xs lg:max-w-xl">
      <h1 className="text-5xl  font-bold text-blue">
        İletişim
      </h1>
      <p className="text-xl p-10 font-light">
        {serverState.text}
      </p>
      <form id="form" onSubmit={handleOnSubmit} name="contact"  method="post"  >
            <input className="block rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="text" name="name" placeholder="İsim Soyisim *" />
            <input className="block rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="email" name="email" placeholder="E-posta adresi *" />
            <input className="block rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" type="text" name="phone" placeholder="Telefon" />
            <textarea className="block rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required rows="5" name="message" placeholder="Mesajınız">       
            </textarea>
            <button className="bg-blue px-5 rounded mt-5 py-2 text-white" type="submit" >Gönder</button>
        </form>
      </div>
    </div>


  </Layout>
  )
}

export default ContactPage
